import React from "react";
// import Chatbot from "react-chatbot-kit";

// import config from "./chatbot/config";
// import ActionProvider from "./chatbot/ActionProvider";
// import MessageParser from "./chatbot/MessageParser";
import logo from './assets/logo.png';
// import "./App.css";
import Landing from "./components/Landing";

import Robot from "./components/Robot";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <header className="table_talk_header">
<img src={logo} className="tabletalk_logo"/>
{/* <div>
  <a href="https://ppasupat.github.io/WikiTableQuestions/viewer/#200-1" target="_blank" className="sample_table">Sample Tables</a>
</div> */}

      </header>
      <div className="hero">
        <div className="sidebar">
          <ul>
            <li>
            who had the greater score in 2012, stanford or usc?
            </li>
            <li>how many consecutive wins did ucla have starting in 2005?</li>
            <li>what is he number of times that san diego state hosted the championship?</li>
            <li>before usc became national champion in 2010, which school was previous chamption?
            UCLA</li>
            <li>how many times was ucla national champion?</li>
            <li>how many years has stanford been national champion?</li>
            <li>in total, how many times has stanford won the national championship?</li>
            <li>the 2011 championship was hosted by university of michigan. what is its venue's name?</li>
          </ul>
        </div>
      <h1>TableTalk Agent</h1>
    <div>
  <Robot/>

    </div>
      <p>
      Access detailed weather reports for major cities like Austin, Boston, Chicago, Los Angeles, New York, and more from 2020 to 2023. Get insights into temperature trends, extreme weather events, and seasonal variations.
         </p>
      </div>
      <div className="table_talk_bot_container">
        <Landing/>
       
      </div>
    </div>
  );
}

export default App;
