import React, { useState ,useRef,useEffect} from 'react';
import './Landing.css';

const Landing = () => {
  const [messages, setMessages] = useState([
    { sender: 'ai', text: "Hi there 🖐. I’m TableTalk Agent, your virtual assistant. I'm here to help with your general enquiries.  " }
  ]);
  const [input, setInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const [isFocused, setIsFocused] = useState(false);
  

  const [loading, setLoading] = useState(false); 
  const messagesEndRef = useRef(null); // Reference for scrolling

  const [isExpanded, setIsExpanded] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);



  const toggleChatbot = () => {
    setIsOpen(!isOpen);
  };

  const handleSend = () => {
    if (input.trim()) {
      setMessages([...messages, { sender: 'user', text: input }]);
      setInput('');
      setLoading(true); 
    }
  };

  function parseMarkdown(text) {
    return marked.parse(text); // Converts Markdown to HTML
}
const sendMessage = async () => {
    if (!input.trim()) return;
  
    const userMessage = { sender: 'user', text: input };
    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setLoading(true); // Ensure loading starts
  
    try {
     // local api
      //  const response = await fetch('http://127.0.0.1:8000/ask', {
        // server api
        // const response = await fetch('http://35.154.139.250:8004/ask', {
           const response = await fetch('/ask', {
        
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ question: input })
      });
  
      const data = await response.json();
      console.log(data, "API response");
  
      const botMessage = { sender: 'ai', text: parseMarkdown(data.text_response || "Sorry, I cannot answer that.") };
      
          // Create a bot message for table_response if it exists
    let tableMessage = null;
    if (data.table_response && Array.isArray(data.table_response)) {
      const tableHtml = generateTableHtml(data.table_response);
      tableMessage = { sender: 'ai', text: tableHtml };
    }
    
      // Hide loading and add bot response
      setMessages(prev => [...prev, botMessage, ...(tableMessage ? [tableMessage] : [])]);
    } catch (error) {
      setMessages(prev => [...prev, { sender: 'ai', text: 'Error reaching the server.' }]);
    } finally {
      setLoading(false); // Ensure loading is disabled
    }
  };
  const formatHeader = (header) => {
    return header
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
  };
  
  const generateTableHtml = (tableData) => {
    if (!tableData || tableData.length === 0) return '';
  
    let tableHtml = '<table border="1" style="border-collapse: collapse; width: 100%;">';
  
    // Extract column names
    const columns = Object.keys(tableData[0]);
  
    // Generate table headers with formatted text
    tableHtml += '<tr>';
    columns.forEach(col => {
      tableHtml += `<th style="padding: 5px; text-align: left;">${formatHeader(col)}</th>`;
    });
    tableHtml += '</tr>';
  
    // Generate table rows
    tableData.forEach(row => {
      tableHtml += '<tr>';
      columns.forEach(col => {
        tableHtml += `<td style="padding: 5px;">${row[col]}</td>`;
      });
      tableHtml += '</tr>';
    });
  
    tableHtml += '</table>';
    return tableHtml;
  };
  
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') sendMessage();
  };
  return (
    <div>
      <div className={`chatbot ${isOpen ? 'chatbot--closed' : 'chatbot--open '}`}>
        <div className="chatbot__header" onClick={toggleChatbot}>
          <p><strong>Got a question?</strong> <span className="u-text-highlight">Ask TableTalk Agent</span></p>
          <div>
          {/* <svg className='min-max'
             onClick={() => setIsExpanded(!isExpanded)}
          xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-minimize-2"><polyline points="4 14 10 14 10 20"/><polyline points="20 10 14 10 14 4"/><line x1="14" x2="21" y1="10" y2="3"/><line x1="3" x2="10" y1="21" y2="14"/></svg> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
          </div>
        </div>
        <div className="chatbot__message-window">
  <ul className="chatbot__messages">
    {messages.map((msg, index) => (
      <li key={index} className={msg.sender === 'ai' ? 'is-ai animation' : 'is-user animation'}>
        {msg.sender === 'ai' && (
          <div className="is-ai__profile-picture">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-bot-message-square"><path d="M12 6V2H8"/><path d="m8 18-4 4V8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"/><path d="M2 12h2"/><path d="M9 11v2"/><path d="M15 11v2"/><path d="M20 12h2"/></svg>
          </div>
        )}
        <span className={msg.sender === 'ai' ? 'chatbot__arrow chatbot__arrow--left' : 'chatbot__arrow chatbot__arrow--right'}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-user-round"><circle cx="12" cy="8" r="5"/><path d="M20 21a8 8 0 0 0-16 0"/></svg>
        </span>
        <p className="chatbot__message">
          <span dangerouslySetInnerHTML={{ __html: msg.text }}></span>
        </p>
      </li>
    ))}
    {loading && (
      <li className="is-ai animation">
        <div className="is-ai__profile-picture">
          <svg className="icon-avatar" viewBox="0 0 32 32">
            <use href="#avatar" />
          </svg>
        </div>
        <span className="chatbot__arrow chatbot__arrow--left"></span>
        <p className="chatbot__message">
          <div className="loading-spinner">
          <span class='loader'><span class='loader__dot'></span><span class='loader__dot'></span><span class='loader__dot'></span></span>
          </div>
        </p>
      </li>
    )}
     {/* Scroll target */}
     <div ref={messagesEndRef}></div>
  </ul>
</div>


        <div className="chatbot__entry">
          <input 
            type="text" 
            className="chatbot__input" 
            placeholder="Write a message..." 
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            onFocus={() => setIsFocused(true)}  // Track focus
            onBlur={() => setIsFocused(false)}  // Track blur (optional)
          />
           {/* <button className={`chatbot__submit ${isFocused ? "enabled" : "disabled"}`} */}
           <button className='chatbot__submit'
       onClick={sendMessage}>
           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-send"><path d="M14.536 21.686a.5.5 0 0 0 .937-.024l6.5-19a.496.496 0 0 0-.635-.635l-19 6.5a.5.5 0 0 0-.024.937l7.93 3.18a2 2 0 0 1 1.112 1.11z"/><path d="m21.854 2.147-10.94 10.939"/></svg>
        </button>
          {/* <svg className="chatbot__submit" viewBox="0 0 32 32" onClick={handleSend}>
            <use href="#icon-send" />
          </svg> */}
        </div>
      </div>
    </div>
  );
};

export default Landing;
